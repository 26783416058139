import styled from "styled-components"

import { ImgCard } from "src/components/Reports/ImgCard"
import { breakpoint } from "src/constants/breakpoints"
import { usePostReportDownloadClicked } from "src/data/analytics/queries/ReportAnalyticsQueries"
import { useTranslate } from "src/i18n/useTranslate"
import { MButton } from "src/ui/Button/MButton"
import imageHomeSetup from "src/ui/images/photo-home-setup.jpg"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function IndoorClimateReportCard() {
  const { t, langKeys } = useTranslate()

  const postReportDownloadClicked = usePostReportDownloadClicked()

  function handleDownloadReport() {
    postReportDownloadClicked.mutate({ type: "indoor_climate" })

    return window.open(
      "https://forms.gle/Bze8PP2Y2Loe9WSw6",
      "_blank",
      "noopener,noreferrer"
    )
  }

  return (
    <ImgCard
      src={imageHomeSetup}
      alt="sofa"
      sources={[{ media: breakpoint.smallUp, srcSet: imageHomeSetup }]}
    >
      <FlexBox>
        <MText variant="heading3">
          {t(langKeys.indoor_climate_report_title)}
        </MText>
      </FlexBox>

      <MText color="secondary">{t(langKeys.indoor_climate_report_body)}</MText>

      <ButtonBox>
        <MButton
          size="medium"
          variant="secondary"
          onClick={handleDownloadReport}
        >
          {t(langKeys.download_report)}
        </MButton>
      </ButtonBox>
    </ImgCard>
  )
}

const ButtonBox = styled.div`
  margin-top: ${spacing.XL};
`

const FlexBox = styled.div`
  display: flex;
  gap: ${spacing.S};
  align-items: center;
`
