import { SourceHTMLAttributes } from "react"
import styled from "styled-components"

import { MCard } from "src/ui/MCard/MCard"
import { spacing } from "src/ui/spacing"

type ImgCardProps = {
  src: string
  alt?: string
  sources: Omit<SourceHTMLAttributes<HTMLSourceElement>, "src">[]
  children: React.ReactNode
}

export function ImgCard({ src, alt, sources, children }: ImgCardProps) {
  return (
    <MCard padding={0} boxShadow={false} border>
      <picture>
        {sources.map((src) => (
          <source
            key={src.srcSet}
            media={src.media ? `(${src.media})` : undefined}
            srcSet={src.srcSet}
          />
        ))}

        <Img src={src} alt={alt ?? ""} loading="lazy" />
      </picture>

      <Contents>{children}</Contents>
    </MCard>
  )
}

const Img = styled.img`
  width: 100%;
  object-fit: cover;
  height: 150px;
`

const Contents = styled.div`
  padding: ${spacing.XL};
`
