import styled from "styled-components"

import { IndoorClimateReportCard } from "src/components/Reports/IndoorClimateReportCard"
import { NoiseIncidentReportCard } from "src/components/Reports/NoiseIncidentReportCard"
import { NoiseSummaryReportCard } from "src/components/Reports/NoiseSummaryReportCard"
import { ReportsFeedbackBox } from "src/components/Reports/ReportsFeedbackBox"
import { useFeatureAvailability } from "src/data/featureAvailability/logic/useFeatureAvailability"
import { usePermissions } from "src/data/user/logic/usePermissions"
import { useTranslate } from "src/i18n/useTranslate"
import { Container } from "src/ui/Container/Container"
import { MainView } from "src/ui/Layout/MainView"
import { spacing } from "src/ui/spacing"

const MIN_WIDTH = "500px"

export function ReportsView() {
  const { t, langKeys } = useTranslate()
  const { available: reportsAvailable } = useFeatureAvailability({
    feature: "reports",
  })
  const { available: reportsProAvailable } = useFeatureAvailability({
    feature: "reports_pro",
  })
  const permissions = usePermissions()

  const title = t(langKeys.reports_title)
  const description = t(langKeys.reports_subtitle)

  if (!reportsAvailable || !permissions.reports) return null

  return (
    <MainView title={title} titleBarProps={{ description }}>
      <StyledContainer>
        <ReportsContainer>
          <NoiseSummaryReportCard
            reportsAvailable={reportsAvailable}
            reportsProAvailable={reportsProAvailable}
          />

          <NoiseIncidentReportCard />

          <IndoorClimateReportCard />
        </ReportsContainer>

        <ReportsFeedbackBox />
      </StyledContainer>
    </MainView>
  )
}

const StyledContainer = styled(Container)`
  display: grid;
  gap: ${spacing.L};
`

const ReportsContainer = styled.div`
  display: grid;
  gap: ${spacing.XL};

  @container (width > ${MIN_WIDTH}) {
    grid-template-columns: 1fr 1fr;
  }
`
