import styled from "styled-components"

import { UpgradeIconButton } from "src/components/FeatureBlockers/UpgradeIconButton"
import { CreateNoiseIncidentReportButton } from "src/components/Reports/CreateNoiseIncidentReportButton"
import { ImgCard } from "src/components/Reports/ImgCard"
import { IncidentReportDialogContainer } from "src/components/Reports/IncidentReportDialogContainer"
import { breakpoint } from "src/constants/breakpoints"
import { useFeatureAvailability } from "src/data/featureAvailability/logic/useFeatureAvailability"
import { useTranslate } from "src/i18n/useTranslate"
import imgSofaSliver from "src/ui/images/photo-sofa-sliver.jpg"
import { MText } from "src/ui/MText"
import { Show } from "src/ui/Show/Show"
import { spacing } from "src/ui/spacing"

export function NoiseIncidentReportCard() {
  const { t, langKeys } = useTranslate()
  const noiseIncidentFeature = useFeatureAvailability({
    feature: "reports_incident",
    refresh: true,
  })

  return (
    <ImgCard
      src={imgSofaSliver}
      alt="sofa"
      sources={[{ media: breakpoint.smallUp, srcSet: imgSofaSliver }]}
    >
      <FlexBox>
        <MText variant="heading3">
          {t(langKeys.noise_incident_report_title)}
        </MText>
        <Show if={!noiseIncidentFeature.available}>
          <UpgradeIconButton context={"incident_reports"} />
        </Show>
      </FlexBox>

      <MText color="secondary">{t(langKeys.noise_incident_report_body)}</MText>

      <ButtonBox>
        <CreateNoiseIncidentReportButton size="medium" variant="secondary" />
        <IncidentReportDialogContainer
          context={"reports_page"}
          keepDataOnCancel
        />
      </ButtonBox>
    </ImgCard>
  )
}

const ButtonBox = styled.div`
  margin-top: ${spacing.XL};
`

const FlexBox = styled.div`
  display: flex;
  gap: ${spacing.S};
  align-items: center;
`
