import styled from "styled-components"

import { useWindowSize } from "usehooks-ts"

import { HomeGroupsDropdown } from "src/components/Dropdown/HomeGroupsDropdown"
import {
  usePostReportDownloadConfirmed,
  usePostReportDownloadInitiated,
} from "src/data/analytics/queries/ReportAnalyticsQueries"
import { TMaybeHomeGroup } from "src/data/homeGroups/types/homeGroupTypes"
import { useEffectOnce } from "src/hooks/useEffectOnce"
import { useTranslate } from "src/i18n/useTranslate"
import { DateRangeDropdown } from "src/ui/DateRangePicker/DateRangeDropdown"
import { TDateRange } from "src/ui/DateRangePicker/DateRangePicker"
import { MDialog, TMDialogProps } from "src/ui/Dialog/MDialog"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

type TReportOptions = {
  dateRange: TDateRange
  setDateRange: (dateRange: TDateRange) => void
  homeGroup: TMaybeHomeGroup
  setHomeGroup: (homegroup: TMaybeHomeGroup) => void
}

type CustomizeReportDialogProps = {
  reportOptions: TReportOptions
  dialogProps: TMDialogProps
}

export function CustomizeReportDialog(props: CustomizeReportDialogProps) {
  if (!props.dialogProps.open) {
    return null
  }

  return <CustomizeReportDialogInner {...props} />
}

function CustomizeReportDialogInner({
  reportOptions,
  dialogProps: { onConfirm, ...dialogProps },
}: CustomizeReportDialogProps) {
  const { t, langKeys } = useTranslate()
  const { height } = useWindowSize()
  const calendarPlacement = height < 900 ? "bottom" : undefined
  const { mutate: mutatePostReportDownloadInitiated } =
    usePostReportDownloadInitiated()
  const { mutate: mutatePostReportDownloadConfirmed } =
    usePostReportDownloadConfirmed()

  useEffectOnce(() => {
    mutatePostReportDownloadInitiated()
  })

  return (
    <MDialog
      {...dialogProps}
      onConfirm={() => {
        mutatePostReportDownloadConfirmed({ type: "filtered_noise" })
        onConfirm?.()
      }}
      confirmLabel={t(langKeys.download)}
      title={t(langKeys.noise_monitoring_summary_header)}
      description={<MText>{t(langKeys.noise_monitoring_summary_body)}</MText>}
    >
      <DialogContents>
        <DateRangeDropdown
          dateRange={reportOptions.dateRange}
          setDateRange={reportOptions.setDateRange}
          dateRangePickerContainerProps={{
            hidePresets: ["YEAR"],
            calendarPlacement,
          }}
        ></DateRangeDropdown>

        <HomeGroupsDropdown
          value={reportOptions.homeGroup}
          onSelect={reportOptions.setHomeGroup}
        ></HomeGroupsDropdown>
      </DialogContents>
    </MDialog>
  )
}

const DialogContents = styled.div`
  display: grid;
  gap: ${spacing.M};
  padding: ${spacing.M};
`
